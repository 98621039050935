

































import { QDialog } from 'quasar'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

export type DebugModalPayload = {
  title: string
  content: unknown
}

@Component({
  components: {
    IconX: () => import('@tada/icons/dist/IconX.vue'),
  },
})
export default class DebugModal extends Vue {
    @Prop({
      type: Object,
    }) readonly payload!: DebugModalPayload

    @Ref() readonly dialog!: QDialog

    public show (): void {
      this.dialog.show()
    }

    private hide (): void {
      this.dialog.hide()
    }

    private onDialogHide () {
      this.$emit('hide')
    }
}
